.slidecontainer {
  width: 150px;
  border-radius: 54px;
  overflow: hidden;
  border-width: 2px;
  border-color: lightgray;
  height: 54px;
  position: relative;
  background-color: white;
}

.slidecontainer-one {
  position: absolute;
  top: 0;
  left: 50px;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  color: black;
}

.slidecontainer-two {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* The slider itself */
.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  z-index: 2;
  background-color: transparent;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 50px;
  height: 50px;
  background: #e64930;
  cursor: pointer;
  border-radius: 50%;
  border-width: 20px;
  border-color: black;
  box-shadow: -98px 0px 0px 80px #f08d7d;
}

.slider::-moz-range-thumb {
  width: 40px;
  height: 40px;
  background: #3880ff;
  cursor: pointer;
  border-radius: 40px;
}

.slider::-moz-range-progress {
  background-color: #43e5f7;
}

.lds-dual-ring {
  display: inline-block;
  width: 36px;
  height: 36px;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 36px;
  height: 36px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
