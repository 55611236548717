.App {
  display: flex;
  flex-direction: column;
  background-color: #222;
  height: 100vh;
  overflow: scroll;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

.text-sub {
  color: gray;
  text-align: left;
  font-size: 14px;
}

.text-main {
  color: white;
  text-align: left;
  font-size: 18px;
}

.ark-button {
  flex: 1;
  margin: 5px;
  border-width: 0.5px;
  border-color: gray;
  border-radius: 5px;
  color: white;
  font-size: 12px;
  padding: 10px;
}

.ark-input {
  font-size: 14px;
  padding: 6px;
  padding-left: 12px;
  border-radius: 20px;
  margin-bottom: 6px;
  margin-top: 6px;
  color: black;
  width: 280px;
}

.ark-error {
  color: red;
  font-size: 12px;
}
